
import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import userImage from "@/components/general/user-image.vue";
import { IndexAccountPayload } from "@/store/modules/account.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        PartUsersShow: () => import("@/views/admin/settings/users/show.vue"),
        PartUsersCreate: () => import("@/views/admin/settings/users/create.vue"),
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormInput,
        FormError,
        FormSubmit,
        userImage,
    },
})
export default class PageAdminUsersIndex extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("account/all") accounts!: Account;
    @Getter("account/viewing") viewingAccount!: Account;

    @Action("account/index") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;
    @Action("account/setViewing") setViewing!: AccountSetViewing;
    @Action("account/clearViewing") clearViewing!: () => void;
    @Action("account/delete") deleteAccount!: (payload: { id: number }) => Promise<void>;

    loaded: boolean = false;
    action: string | null = null;

    created() {
        this.indexAccounts({ q: { or: { roles: ["administrator", "user"] } }, s: { created_at: "asc" }, per_page: 99 }).then(() => {
            this.loaded = true;
        });
    }

    showCreate() {
        this.action = "create";
    }

    showEdit(account: Account) {
        this.action = "edit";
        this.setViewing(account);
    }

    showDelete(account: Account) {
        this.action = "delete";
        this.setViewing(account);
    }

    handleDeleteAccount() {
        this.deleteAccount({ id: this.viewingAccount.id as unknown as number }).then(() => {
            this.$toast.open({ message: this.$t("views.settings.admin-users.delete_success") as string, type: "success", position: "bottom-right" });
            this.action = null;
            this.clearViewing();
        });
    }
}
